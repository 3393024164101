<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="4">
          <v-card dense color="rgb(56 115 69)">
            <v-card-title class="text-h5">Update Ship List</v-card-title>
            <v-card-subtitle class="d-flex flex-column">
              <div>Manuell Update ship db with RSI db (auto update every day at 04:00)</div>
              <div>{{getAdminLog('updateShips')}}</div>
            </v-card-subtitle>
            <v-card-actions @click="updateShips"><v-btn text>Update Now</v-btn></v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card dense color="rgb(115 100 56)">
            <v-card-title class="text-h5">Update User List</v-card-title>
            <v-card-subtitle class="d-flex flex-column">
              <div>Manuell Update user db with RSI db (auto update every day at 04:00)</div>
              <div class="mt-auto">{{getAdminLog('updateUsers')}}</div>
            </v-card-subtitle>
            <v-card-actions @click="updateUsers"><v-btn text>Update Now</v-btn></v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card dense color="#385F73">
            <v-card-title class="text-h5">Download Wild Hangar DB</v-card-title>
            <v-card-subtitle class="d-flex flex-column">
              <div><v-icon small>fas fa-hammer</v-icon> Under construction</div>
              <!-- <div>Download Wild Hangar DB (auto backup every day at 04:00)</div> -->
              <!-- <div>{{getAdminLog('backupDB')}}</div> -->
            </v-card-subtitle>
            <!-- <v-card-actions @click="downloadDB"><v-btn text>Backup Now</v-btn></v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-4">
        <v-card-title>
          Error logs
          <v-btn v-if="logs.length" class="ml-4" color="red" icon @click="deleteLogs">
            <v-icon>fa-trash</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        </v-card-title>
        <v-data-table
          class="mt-4"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :search="search"
          :headers="headers"
          :items="parsedLogs"
          :items-per-page="15"
        >
        <template v-slot:[`item.date`]="{ item }">
          <div>{{ formatDate(item.date) }}</div>
        </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import { format } from 'date-fns';
  import { de } from 'date-fns/locale'
  import { differenceInHours, parseISO } from 'date-fns';

  export default {
    name: 'HelloWorld',

    components: {
      Topbar
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        const user = vm.$store.getters['user/getUser'];
        if (user.rank !== 'Wildest One') {
          next({ name: 'dashboard' })
        }
      });
    },

    data() {
      return {
        navbarTitle: 'Admin area',
        search: '',
        parsedLogs: [],
        sortBy: 'date',
        sortDesc: true,
        headers: [
          { text: 'Type', value: 'type', align: 'start', width: 20 },
          { text: 'Title', sortable: false, value: 'title', width: 150 },
          { text: 'Date', value: 'date', sortDesc: true, sortable: true, width: 150 },
          { text: 'Error Id', value: 'errorId', width: 100 },
          { text: 'Description', value: 'description', sortable: false, width: 150 },
          { text: 'Data', value: 'data', sortable: false, width: 150 }
        ]
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      adminLogs() {
        return this.$store.getters['admin/getAdminLogs'];
      },
      logs() {
        return this.$store.getters['admin/getLogs'];
      }
    },

    watch: {
      logs(logs) {
        const parsedLogs = [];
        for (let i = 0; i < logs.length; i++) {
          parsedLogs.push({
            ...logs[i],
            type: logs[i].type === 'log' ? '✅' : '⛔️',
            // date: format(new Date(logs[i].date), 'dd MMMM yyyy HH:mm:ss', { locale: de })
          })
        }
        this.parsedLogs = parsedLogs;
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        await this.$store.dispatch('admin/getLogs');
        await this.$store.dispatch('admin/getAdminLogs');
        this.$store.dispatch('loading', false, {root: true});
      },
      async deleteLogs() {
        this.$store.dispatch('admin/deleteLogs');
        this.$swal({
          toast: true,
          position: 'top-end',
          title: 'Logs deleted',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        });
      },
      async updateShips() {
        const db = await this.$store.dispatch('admin/getAdminLog', {type: 'lock'});
        if (db && db.type === 'lock') {
          this.showError();
          return;
        }
        this.$swal({
          title: 'Update ships DB?',
          text: 'This takes about 5 minutes',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('admin/addAdminLog', {userName: this.user.name, type: 'updateShips'});
              await this.$store.dispatch('admin/updateShips');
              this.showSuccess();
              this.loadItems();
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      async updateUsers() {
        const db = await this.$store.dispatch('admin/getAdminLog', {type: 'lock'});
        if (db.locked) {
          this.showError();
          return;
        }
        this.$swal({
          title: 'Update User DB?',
          text: 'This takes about 6 minutes',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              await this.$store.dispatch('admin/addAdminLog', {userName: this.user.name, type: 'updateUsers'});
              await this.$store.dispatch('admin/updateUsers');
              this.showSuccess();
              this.loadItems();
            } catch (error) {
            }
          }
        });
      },
      // async downloadDB() {
      //   const canBackup = await this.checkLastUpdate('downloadDB');
      //   if (!canBackup) {
      //     this.showError();
      //     return;
      //   }

      //   this.showSuccess();
      // },
      // async checkLastUpdate(type) {
      //   const checkLastUpdate = await this.$store.dispatch('admin/getAdminLog', {type});
      //   if (checkLastUpdate && checkLastUpdate.date) {
      //     if (differenceInHours(new Date(), parseISO(checkLastUpdate.date)) < 1) {
      //       return false;
      //     }
      //   }
      //   return true;
      // },
      formatDate(date) {
        return format(new Date(date), 'dd MMMM yyyy HH:mm:ss', { locale: de })
      },
      showError() {
        this.$swal({
          title: 'update/backup in progress',
          icon: 'error'
        });
      },
      showSuccess() {
        this.$swal({
          toast: true,
          position: 'top-end',
          title: 'update successful',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        });
      },
      getAdminLog(type) {
        const log = this.adminLogs.find((item) => item.type === type);
        let text = '';

        if (log) {
          switch (log.type) {
            case 'updateShips':
              text = `Last Update started by ${log.userName} at ${format(new Date(log.date), 'EEE dd MMMM yyyy HH:mm:ss', { locale: de })}`
              break;
            case 'updateUsers':
              text = `Last Update started by ${log.userName} at ${format(new Date(log.date), 'EEE dd MMMM yyyy HH:mm:ss', { locale: de })}`
              break;
            // case 'backupDB':
            //   text = `Last Backup done by ${log.userName} at ${format(new Date(log.date), 'EEE dd MMMM yyyy HH:mm:ss', { locale: de })}`
            //   break;
          }
        }
        return text;
      }
    }
  }
</script>
